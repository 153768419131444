var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h1',{staticClass:"display-1"},[_vm._v("All Bookings")])]),_c('v-col',{attrs:{"cols":"12","md":"4","align":"end"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary","to":"/"}},[_vm._v("Home")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-switch',{attrs:{"dense":"","inset":"","hide-details":"","label":"View Confirmed Bookings"},model:{value:(_vm.viewConfirmed),callback:function ($$v) {_vm.viewConfirmed=$$v},expression:"viewConfirmed"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-switch',{attrs:{"dense":"","inset":"","hide-details":"","label":"View Archived Bookings"},model:{value:(_vm.viewArchived),callback:function ($$v) {_vm.viewArchived=$$v},expression:"viewArchived"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"text--primary"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('h3',{staticClass:"title"},[_vm._v("Active/Unconfirmed Bookings")])]),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":"Search Active/Unconfirmed","dense":"","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.searchActiveUnconfirmed),callback:function ($$v) {_vm.searchActiveUnconfirmed=$$v},expression:"searchActiveUnconfirmed"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.activeBookings,"dense":"","search":_vm.searchActiveUnconfirmed,"items-per-page":-1,"item-class":_vm.itemClass,"hide-default-footer":""},on:{"click:row":_vm.onViewBooking},scopedSlots:_vm._u([{key:"item.checkInDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.checkInDate))+" ")]}},{key:"item.checkOutDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.checkOutDate))+" ")]}},{key:"item.dateConfirmed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dateConfirmed))+" ")]}}],null,true)})],1)],1)],1),_c('v-fade-transition',[(_vm.viewConfirmed)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"text--primary"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('h3',{staticClass:"title"},[_vm._v("Confirmed Bookings")])]),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":"Search Confirmed","dense":"","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.searchConfirmed),callback:function ($$v) {_vm.searchConfirmed=$$v},expression:"searchConfirmed"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.confirmedBookings,"dense":"","search":_vm.searchConfirmed,"items-per-page":-1,"item-class":_vm.itemClass,"hide-default-footer":""},on:{"click:row":_vm.onViewBooking},scopedSlots:_vm._u([{key:"item.checkInDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.checkInDate))+" ")]}},{key:"item.checkOutDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.checkOutDate))+" ")]}},{key:"item.dateConfirmed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dateConfirmed))+" ")]}}],null,true)})],1)],1)],1):_vm._e()],1),_c('v-fade-transition',[(_vm.viewArchived)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"text--primary"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('h3',{staticClass:"title"},[_vm._v("Archived Bookings")])]),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":"Search Archived","dense":"","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.searchArchived),callback:function ($$v) {_vm.searchArchived=$$v},expression:"searchArchived"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.archivedBookings,"dense":"","search":_vm.searchArchived,"items-per-page":-1,"item-class":_vm.itemClass,"hide-default-footer":""},on:{"click:row":_vm.onViewBooking},scopedSlots:_vm._u([{key:"item.checkInDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.checkInDate))+" ")]}},{key:"item.checkOutDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.checkOutDate))+" ")]}},{key:"item.dateConfirmed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.dateConfirmed))+" ")]}}],null,true)})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }