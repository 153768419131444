<template>
  <v-container>
    <!-- page header -->
    <v-row>
      <v-col cols="12" md="8">
        <h1 class="display-1">All Bookings</h1>
      </v-col>
      <v-col cols="12" md="4" align="end">
        <v-btn depressed color="primary" to="/">Home</v-btn>
      </v-col>
    </v-row>
    <!-- page header -->

    <!-- screen options -->
    <v-row>
      <v-col cols="12" md="3"
        ><v-switch
          v-model="viewConfirmed"
          dense
          inset
          hide-details
          label="View Confirmed Bookings"
        ></v-switch
      ></v-col>
      <v-col cols="12" md="3"
        ><v-switch
          v-model="viewArchived"
          dense
          inset
          hide-details
          label="View Archived Bookings"
        ></v-switch
      ></v-col>
    </v-row>
    <!-- screen options -->

    <!-- active/unconfirmed data table -->
    <v-row
      ><v-col cols="12">
        <v-card outlined>
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" md="7">
                <h3 class="title">Active/Unconfirmed Bookings</h3>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  label="Search Active/Unconfirmed"
                  v-model="searchActiveUnconfirmed"
                  dense
                  outlined
                  clearable
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-data-table
            :headers="headers"
            :items="activeBookings"
            dense
            :search="searchActiveUnconfirmed"
            :items-per-page="-1"
            :item-class="itemClass"
            @click:row="onViewBooking"
            hide-default-footer
          >
            <!-- <template v-slot:[`item.name`]="{ item }">
              {{ item.firstName }} {{ item.lastName }}
            </template> -->
            <template v-slot:[`item.checkInDate`]="{ item }">
              {{ formatDate(item.checkInDate) }}
            </template>
            <template v-slot:[`item.checkOutDate`]="{ item }">
              {{ formatDate(item.checkOutDate) }}
            </template>
            <template v-slot:[`item.dateConfirmed`]="{ item }">
              {{ formatDate(item.dateConfirmed) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col></v-row
    >
    <!-- active/unconfirmed data table -->

    <!-- confirmed data table -->
    <v-fade-transition>
      <v-row v-if="viewConfirmed"
        ><v-col cols="12">
          <v-card outlined>
            <v-card-text class="text--primary">
              <v-row>
                <v-col cols="12" md="7">
                  <h3 class="title">Confirmed Bookings</h3>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Search Confirmed"
                    v-model="searchConfirmed"
                    dense
                    outlined
                    clearable
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-data-table
              :headers="headers"
              :items="confirmedBookings"
              dense
              :search="searchConfirmed"
              :items-per-page="-1"
              :item-class="itemClass"
              @click:row="onViewBooking"
              hide-default-footer
            >
              <!-- <template v-slot:[`item.name`]="{ item }">
                {{ item.firstName }} {{ item.lastName }}
              </template> -->
              <template v-slot:[`item.checkInDate`]="{ item }">
                {{ formatDate(item.checkInDate) }}
              </template>
              <template v-slot:[`item.checkOutDate`]="{ item }">
                {{ formatDate(item.checkOutDate) }}
              </template>
              <template v-slot:[`item.dateConfirmed`]="{ item }">
                {{ formatDate(item.dateConfirmed) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col></v-row
      >
    </v-fade-transition>
    <!-- confirmed data table -->

    <!-- archived data table -->
    <v-fade-transition>
      <v-row v-if="viewArchived"
        ><v-col cols="12">
          <v-card outlined>
            <v-card-text class="text--primary">
              <v-row>
                <v-col cols="12" md="7">
                  <h3 class="title">Archived Bookings</h3>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    label="Search Archived"
                    v-model="searchArchived"
                    dense
                    outlined
                    clearable
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-data-table
              :headers="headers"
              :items="archivedBookings"
              dense
              :search="searchArchived"
              :items-per-page="-1"
              :item-class="itemClass"
              @click:row="onViewBooking"
              hide-default-footer
            >
              <!-- <template v-slot:[`item.name`]="{ item }">
                {{ item.firstName }} {{ item.lastName }}
              </template> -->
              <template v-slot:[`item.checkInDate`]="{ item }">
                {{ formatDate(item.checkInDate) }}
              </template>
              <template v-slot:[`item.checkOutDate`]="{ item }">
                {{ formatDate(item.checkOutDate) }}
              </template>
              <template v-slot:[`item.dateConfirmed`]="{ item }">
                {{ formatDate(item.dateConfirmed) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col></v-row
      >
    </v-fade-transition>
    <!-- archived data table -->
  </v-container>
</template>

<script>
import firebase from "@/plugins/firebase";
import formatDate from "@/mixins/formatDate";
import moment from "moment";
export default {
  mixins: [formatDate],
  firestore: {
    bookings: firebase
      .firestore()
      .collection("bookingsConfirmations")
      .orderBy("dateConfirmed", "desc"),
  },
  data: () => ({
    bookings: [],
    searchActiveUnconfirmed: "",
    searchConfirmed: "",
    searchArchived: "",
    viewConfirmed: false,
    viewArchived: false,
    headers: [
      { text: "Last Name", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Resort Name", value: "resortName" },
      { text: "Check In Date", value: "checkInDate" },
      { text: "Check Out Date", value: "checkOutDate" },
      { text: "Date Confirmed", value: "dateConfirmed" },
      { text: "Confirmation #", value: "confirmationNumber" },
      { text: "Supplier", value: "source" },
    ],
  }),
  computed: {
    activeBookings() {
      if (!this.bookings) return;
      return this.bookings.filter((i) => !i.isConfirmed && !i.archived);
    },
    confirmedBookings() {
      if (!this.bookings) return;
      return this.bookings.filter((i) => i.isConfirmed && !i.archived);
    },
    archivedBookings() {
      if (!this.bookings) return;
      return this.bookings.filter((i) => i.archived);
    },
  },
  methods: {
    itemClass(item) {
      let today = moment().startOf("day");
      let checkOutDate = moment(item.checkOutDate);
      if (item.isConfirmed && checkOutDate.isAfter(today))
        return "green lighten-4";
      if (item.isConfirmed && checkOutDate.isBefore(today))
        return "grey lighten-2";
      return "";
    },
    onViewBooking(item) {
      this.$router.push(`bookings/${item.id}`);
    },
  },
};
</script>

<style>
table {
  width: 100%;
  table-layout: fixed;
}
tr {
  cursor: pointer;
}
</style>
